<template>
    <v-row justify="center">
      <v-dialog v-model="show" persistent max-width="500" overlay-opacity="0.5">
        <v-card>
          <v-card-text style="height:250px">
            <br />
            <v-layout row wrap align-center justify-center>
              <v-flex xs11 lg11 ma-5>
                <v-card class="elevation-0">
                  <v-layout row wrap justify-center>
                    <v-flex lg10 xs10 class="text-center mt-4">
                      <h2>เลขบัตรประจำตัวประชาชนนี้</h2><br/>
                      <h2>มีการใช้งาน OneID แล้ว</h2>
                    </v-flex>
                  </v-layout>
  
                  <v-layout row wrap justify-center>
                    <v-flex lg12 xs12 class="text-center">
                      <!-- <v-divider class="mt-6"></v-divider> -->
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <!-- <v-flex lg5 xs5 class="text-right mt-6">
                      <v-btn class="ma-2" color="red" outlined @click="closedialog()">มี OneID</v-btn>
                    </v-flex> -->
                    <v-flex lg5 xs5 class="text-center ma-6 ">
                      <v-btn class="ma-2" dark :color="color.theme" @click="closedialog()">รับทราบ</v-btn>
                    </v-flex>
                    <br/>
                    <br/>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <script>
  import { mapState, mapGetters } from "vuex";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  import VueCookies from "vue-cookies";
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  export default {
    data: function() {
      return {
        dialog: "",
        rememberme: true,
      };
    },
    computed: {
      ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
      ]),
    },
    props: ["show"],
    methods: {
      closedialog() {
        this.$emit("closedialog")
        this.$router.push("/moph/loginwithid");
      },
      // fn_register_mohpromt() {
      //   this.$emit("closedialog")
      //   this.$emit("nextstep")
        
 
      // },
    },
  };
  </script>
  <style></style>
  